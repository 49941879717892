import React, { ChangeEvent, useEffect, useState } from 'react'
import '../styles/default.min.css'
import axios from '../api/axios';

import DataList from '../components/DataList'
import ListItem from '../components/ListItem'
import { useParams } from 'react-router'
import Modal from 'react-modal';

interface Props {
  title: string,
  addText: string,
  endpoint: string
}

interface Data {
  _id: string,
  id: string,
  name: string
  category: string
}

export default function DataView({title, addText, endpoint}: Props) {
  const [data, setData] = useState<Array<Data>>([])
  const [modalOpen, setModalOpen] = useState(false);
  const {client} = useParams()

  var categories: Array<string> = [];

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  function loadData() {
    axios
      .get(`/${endpoint}?c=${client}`)
      .then(res => {
        setData(res.data as Array<Data>);
      })
      .catch(err => {
        console.error(err);
      });
  }

  useEffect((() => loadData()), [endpoint])

  data.sort((a, b) => a.name > b.name ? 1 : 0)

  const customStyles = {
    content: {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  if (endpoint === 'entities') {
    categories = ["Meest gekozen", "Overig"];
  }
  else {
    categories = ["Huurder/klant", "Personeel"];
  }
  
  const [newData, setNewData] = useState({category:categories[0]})

  function handleChange(e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    setNewData({...newData, [e.target.name]: e.target.value});
  }

  function submitData() {
    axios
      .post(`/add-${endpoint}?c=${client}`, newData)
      .then(_ => {
        loadData();
        setModalOpen(false);
      });
  }

  function deleteData(datum: Data) {
    if (window.confirm(`Weet je zeker dat je ${datum.name} wil verwijderen?`)) {
      axios
        .post(`/delete-${endpoint}?c=${client}`, datum)
        .then(() => loadData());
    }
  }

  return (
    <div className='view'>
      <h1 className="view-title">{title}</h1>
      <button className='btn-add' onClick={() => setModalOpen(true)}>{addText}</button>
      <DataList>
        {data.map((item, key) => (
          <ListItem
            title={item.name}
            category={item.category}
            deleteFunc={() => deleteData({
              _id: item._id,
              id: item.id,
              name: item.name,
              category: item.category
            })}
            key={key} />
        ))}
      </DataList>
      <Modal
        isOpen={modalOpen}
        style={customStyles}
        onRequestClose={() => toggleModal()}
        contentLabel="Title">
        <h1 className='modal-title'>{addText}</h1>
        <div className='modal-form-field'>
          <label htmlFor="new-data-title">Naam</label>
          <input 
            type="text"
            name="name"
            id="name"
            className='modal-textinput'
            onChange={e => handleChange(e)} />
        </div>
        <div className='modal-form-field'>
          <label htmlFor="new-data-title">Categorie</label>
          <select name="category" id="category" value={newData.category} onChange={e => handleChange(e)}>
            {categories.map((category, key) => (
              <option value={category} key={key}>{category}</option>
            ))}
          </select>
        </div>
        {/* TODO: Category */}
        <button className='modal-btn-add' onClick={() => submitData()}>Voeg toe</button>
      </Modal>
    </div>
  )
}