import { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";

interface SidePanelButtonProps {
  name: string,
  to: string
}

export default function SidePanelButton({name, to}: SidePanelButtonProps): ReactElement {
  const route = useLocation().pathname.split("/");  // first part of the path
  const last = route[route.length - 1];
  const pointer = to.split('/')[1];

  return (
    <Link to={to}>
      <li className={last === pointer ? "nav-active" : "nav-inactive"}>
        {name}
      </li>
    </Link>
  )
}