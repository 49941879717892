import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../styles/default.min.css'
import '../styles/settings.min.css'
import axios from '../api/axios';

interface Client {
  _id: string,
  name: string,
  hash: string,
  color: string,
  website: string,
  contact: string
}

export default function ClientSettingsView() {
  const [clientObj, setClient] = useState({
    '_id': '',
    'name': '',
    'hash': '',
    'color': '',
    'website': '',
    'contact': ''
  });
  const [editMode, setEditMode] = useState(false);
  const {client} = useParams();

  function loadClient() {
    axios
      .get(`/load-client?c=${client}`)
      .then(res => {
        setClient(res.data as Client);
      });
  }

  useEffect(() => loadClient(), []);

  let navigate = useNavigate();

  function deleteClient() {
    if (window.confirm(`Weet je zeker dat je ${clientObj.name} wil verwijderen?`)) {
      if (window.confirm(`Weet je echt zeker dat je ${clientObj.name} wil verwijderen?`)) {
        axios
          .post('/delete-client', clientObj)
          .then(_ => {
            navigate('/klanten');
          });
      }
    }
  }

  let formStyle = editMode ? 'initial' : 'none';
  let textStyle = editMode ? 'none' : 'initial';

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setClient({...clientObj, [e.target.name]: e.target.value});
  }

  function save() {
    axios
      .post('/edit-client', clientObj)
      .then(() => {
        loadClient();
        setEditMode(false);
      });
  }

  return (
    <div className='view'>
      <h1 className="view-title">Instellingen</h1>
      <button style={{display: textStyle}} className='btn-add' onClick={() => setEditMode(!editMode)}>Wijzig klant</button>
      
      <div style={{display: formStyle}}>
        <button className='btn-add' onClick={() => save()}>Wijzigen</button>
        <button className='btn-add' style={{backgroundColor: '#EA4949', marginLeft: '10px'}} onClick={() => setEditMode(false)}>Annuleren</button>
      </div>

      <div className='list-settings'>
        <div className='setting'>
          <h3>Naam</h3>
          <p style={{display: textStyle}}>{clientObj.name}</p>
          <input 
            type="text"
            name="name"
            value={clientObj.name}
            id="name"
            onChange={e => handleChange(e)}
            style={{display: formStyle}}/>
        </div>

        <div className='setting'>
          <h3>Hash</h3>
          <a href={`https://magikditdelen.nl/${clientObj.hash}`} target='_blank'>{clientObj.hash}</a>
        </div>

        <div className='setting'>
          <h3>Kleur</h3>
          <p style={{display: textStyle}}>{clientObj.color}</p>
          <input
            type="text"
            name="color"
            value={clientObj.color}
            id="color"
            onChange={e => handleChange(e)}
            style={{display: formStyle}} />
        </div>

        <div className='setting'>
          <h3>Website</h3>
          <p style={{display: textStyle}}>{clientObj.website}</p>
          <input
            type="text"
            name="website"
            value={clientObj.website}
            id="website"
            onChange={e => handleChange(e)}
            style={{display: formStyle}} />
        </div>

        <div className='setting'>
          <h3>Contact</h3>
          <p style={{display: textStyle}}>{clientObj.contact}</p>
          <input
            type="text"
            name="contact"
            value={clientObj.contact}
            id="contact"
            onChange={e => handleChange(e)}
            style={{display: formStyle}} />
        </div>
      </div>
      <button className='btn-delete' onClick={() => deleteClient()}>Verwijder klant</button>
    </div>
  )
}