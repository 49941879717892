import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import { 
  BrowserRouter as Router, 
  Routes, 
  Route 
} from 'react-router-dom';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Modal from 'react-modal';

// TODO: Use createRoot
const rootElement = document.getElementById("root") 
const root = createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Dashboard />} />
        </Routes>
      </Router>
    </RecoilRoot>
  </React.StrictMode>
);

Modal.setAppElement('#root');