import { atom } from 'recoil'

export const authorizedState = atom({
  key: 'isAuthorized',
  default: false
})

export const isLoadingDataState = atom({
  key: 'isLoadingData',
  default: false
})