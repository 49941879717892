import { ReactElement, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import axios from '../api/axios';

import '../styles/login.min.css';
import LoginImage from '../res/login.png';
import { authorizedState } from '../atoms/global';
import { useNavigate } from 'react-router-dom';

export default function Login(): ReactElement {
  const [isSubmitted, setSubmitted] = useState(false);
  const [isAuthorized, setAuthorized] = useRecoilState(authorizedState);
  const [loginState, setLoginState] = useState({
    "username": "",
    "password": ""
  });
  
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState, 
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    axios.post('/login', {
      username: loginState.username,
      password: loginState.password
    })
    .then(res => {
      if (res.data.isAuthorized) {
        localStorage.setItem('token', res.data.token);
        navigate('/');
      }

      setAuthorized(res.data.isAuthorized);
      setSubmitted(true);
    });
  }

  const errorMessage = (
    <p>Onjuiste inloggegevens</p>
  )

  return (
    <div className='page-login'>
      {/* {isAuthorized && <> */}
      <div className='left'>
        <img src={LoginImage} className="login-image" />
        <div className="diagonal"></div>
      </div>
      <div className='right'>
        <div className="login-box">
          <h1 className='login-title'>VVA Admin</h1>
          
          <p className='login-description'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, itaque odit delectus.</p>
          
          <form method="POST" onSubmit={handleSubmit}>
            <input 
              onChange={handleChange} 
              type="text" 
              name="username" 
              id="login-user" 
              placeholder="Gebruikersnaam" />
              
            <input 
              onChange={handleChange} 
              type="password" 
              name="password" 
              id="login-password" 
              placeholder="Wachtwoord" />
          
            {isSubmitted && !isAuthorized && errorMessage}

            <button className='btn-login' type='submit'>Log in</button>
          </form>
        </div>
      </div>
      {/* </>} */}
    </div>
  )
}
