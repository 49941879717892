import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../styles/default.min.css'
import axios from '../api/axios';
import Modal from 'react-modal'

interface Client {
  _id: string,
  name: string,
  color: string
}

export default function ClientView(): ReactElement {
  const [clients, setClients] = useState<Array<Client>>([]);
  const [newClient, setNewClient] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  function loadClients() {
    axios
      .get(`/clients`)
      .then(clients => {
        if (clients.data) {
          const data = clients.data
            .sort((a: Client, b: Client) => (
              b.name.toLowerCase() < a.name.toLowerCase()
            ));

          setClients(data);
        } 
      })
      .catch(err => {
        console.error(err);
      });
  }

  useEffect(() => loadClients(), [])

  function addClient() {
    axios
      .post('/add-client', newClient)
      .then(_ => {
        loadClients();
        setModalOpen(false);
      });
  }

  function handleChange(e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    setNewClient({...newClient, [e.target.name]: e.target.value});
  }

  const customStyles = {
    content: {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <div className='view'>
      <h1 className="view-title">Klanten</h1>
      <div className='client-container'>
        {clients.map((client, key) => {
          return (
            <Link to={client._id} key={key}>
              <div 
                className='client'
                style={{
                  borderColor: client.color,
                  color: client.color
                }}>
                {client.name}
              </div>
            </Link>
          )
        })}
        
        <div className='client-new' onClick={() => setModalOpen(true)}>+</div>
      </div>

      <Modal
        isOpen={modalOpen}
        style={customStyles}
        onRequestClose={() => setModalOpen(false)}
        contentLabel="Title">
        <h1 className='modal-title'>Nieuwe Klant</h1>
        <div className='modal-form-field'>
          <label htmlFor="name">Naam</label>
          <input 
            type="text"
            name="name"
            id="name"
            className='modal-textinput'
            onChange={e => handleChange(e)} />
        </div>
        
        <div className='modal-form-field'>
          <label htmlFor="color">Kleur</label>
          <input 
            type="text"
            name="color"
            placeholder='#25AA6B'
            id="name"
            className='modal-textinput'
            onChange={e => handleChange(e)} />
        </div>

        <div className='modal-form-field'>
          <label htmlFor="website">Website</label>
          <input 
            type="url"
            name="website"
            placeholder='https://innovatisering.nl'
            id="name"
            className='modal-textinput'
            onChange={e => handleChange(e)} />
        </div>

        <div className='modal-form-field'>
          <label htmlFor="contact">Contact</label>
          <input 
            type="email"
            name="contact"
            placeholder='info@innovatisering.nl'
            id="name"
            className='modal-textinput'
            onChange={e => handleChange(e)} />
        </div>
        <button className='modal-btn-add' onClick={() => addClient()}>Voeg toe</button>
      </Modal>
    </div>
  )
}
