import React from 'react'

import '../styles/default.min.css'
import '../styles/data.min.css'

interface Props {
  title: string,
  category: string,
  deleteFunc: () => void
}

export default function ListItem({title, category, deleteFunc}: Props) {
  return (
    <div className='list-item'>
      <p>{title}</p>
      {/* Show category <p className='list-item-category'>{category}</p> */}
      <button className='list-item-delete' onClick={deleteFunc}>&#10060;</button>
    </div>
  )
}