import { ChangeEvent, useEffect, useState } from 'react'
import '../styles/data.min.css'
import axios from '../api/axios';
import { useParams } from 'react-router'

type Props = {}

interface Data {
  _id: string,
  id: string,
  name: string
  category: string
}

interface ChangedBranches {
  [_id: string]: {
    result: boolean,
    explanation: string
  }
}

interface Branch {
  _id: string,
  id: string,
  entity: string,
  entityName: string,
  data: string,
  explanation: string,
  result: boolean 
}

export default function TreeView({}: Props) {
  const [data, setData] = useState<Array<Data>>([]);
  const [selectedDatum, setSelectedDatum] = useState("");
  const [branches, setBranches] = useState<Array<Branch>>([])
  const [changedData, setChangedData] = useState<ChangedBranches>({})
  const {client} = useParams();

  useEffect(() => {
    axios
      .get(`/data?c=${client}`)
      .then(res => {
        setData(res.data as Array<Data>);
        if (res.data.length > 0) {
          setSelectedDatum(res.data[0].id);
          loadBranches(res.data[0].id);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, [])

  data.sort((a, b) => a.name > b.name ? 1 : 0)

  function loadBranches(datum: string) {
    axios
      .post(`/tree`, {
        'data': datum,
        'clientID': client
      })
      .then(res => {
        setBranches(res.data as Array<Branch>);
      })
      .catch(err => {
        console.error(err);
      });
  }

  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    setSelectedDatum(e.target.value)
    loadBranches(e.target.value);
  }

  function handleTextFieldChange(e: ChangeEvent<any>, _id: string) {
    e.preventDefault();
    
    setChangedData({
      ...changedData,
      [_id]: {
        ...changedData[_id],
        explanation: e.target.value
      }
      });
  }

  function handleCheckboxChange(e: ChangeEvent<HTMLInputElement>, _id: string) {
    setChangedData({...changedData, [_id]: { 
      ...changedData[_id],
      result: e.target.checked
    }});
  }

  function saveChanged() {
    axios.post(`/update-tree`, {
      'changedData': changedData,
      'clientID': client
    })
    .then(_ => {
      setChangedData({});
    });
  }

  return (
    <div className='view'>
      <h1 className="view-title">Beslisboom</h1>
      <select name="dd-data" id="dd-data" className='dropdown' onChange={e => handleChange(e)}>
        {data.map((datum, key) => (
          <option value={datum.id} key={key}>
            {datum.name}
          </option>
        ))}
      </select>
      <div className='branch-list'>
        {branches.map(branch => {

          return (<div 
            className='branch-item'
            key={branch._id}>
            <h3>{branch.entityName}</h3>
            <textarea
              name="txt"
              id="txt"
              cols={40}
              rows={2}
              defaultValue={branch.explanation}
              onChange={e => handleTextFieldChange(e, branch._id)}>
            </textarea>
            <div className='branch-item-result'>
              <input
                type="checkbox"
                name={`result-${branch._id}`}
                id={`result-${branch._id}`}
                key={branch._id}
                defaultChecked={branch.result}
                onChange={e => handleCheckboxChange(e, branch._id)}/>
              <label htmlFor="result">Mag gedeeld worden?</label>
            </div>
          </div>
        )})}
      </div>
      {Object.keys(changedData).length !== 0 ? 
      (<div className='btn-save' style={{'backgroundColor':'darkorange'}}>
        Niet opgeslagen
        <button onClick={saveChanged}>Opslaan</button>
      </div>) : 
      (<button className='btn-save' style={{'backgroundColor':'green'}}>Opgeslagen</button>)}
    </div>
  )
}