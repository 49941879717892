import { ReactElement, useEffect } from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route,
  useNavigate,
  Navigate
} from 'react-router-dom';

import '../styles/default.min.css'

import axios from '../api/axios';
import { useRecoilState } from 'recoil';
import { authorizedState } from '../atoms/global';
import SidePanel from '../components/SidePanel';
import SidePanelButton from '../components/SidePanelButton';

import ClientView from './ClientView';
import DataView from './DataView';
import TreeView from './TreeView';
import ClientSettingsView from './ClientSettingsView';

export default function Dashboard(): ReactElement {
  const [isAuthorized, setAuthorized] = useRecoilState(authorizedState);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get("/authenticated", {
      headers: {
        "x-access-token": localStorage.getItem("token") as string
      }
    })
    .then(res => {
      setAuthorized(res.data)
  
      if (!res.data) {
        navigate('/login');
      }
    })
    .catch((err) => {
      console.error(err)
      navigate('/login');
    })
  }, []);
  
  return (
    <div className="dashboard">
      {isAuthorized && <>
        
        <Routes>
          <Route path='/*' element={(
            <SidePanel>
              <SidePanelButton name="Klanten" to="/klanten"/>
            </SidePanel>
          )}/>

          <Route path='/klanten/:client/*' element={(
            <SidePanel>
              <SidePanelButton name="Beslisboom" to="./beslisboom"/>
              <SidePanelButton name="Entiteiten" to="./entiteiten"/>
              <SidePanelButton name="Gegevens"  to="./gegevens"/>
            </SidePanel>
          )}/>
        </Routes>
    
        <Routes>
          <Route path='/klanten/:client/beslisboom' element={<TreeView />}></Route>
          <Route path='/klanten/:client/entiteiten' element={(
            <DataView 
              title="Entiteiten"
              addText='Nieuwe Entiteit'
              endpoint='entities' />
          )}></Route>
          <Route path='/klanten/:client/gegevens' element={(
            <DataView
              title="Gegevens"
              addText='Nieuw Gegeven'
              endpoint='data'/>
          )}></Route>
          <Route path='/klanten/:client/instellingen' element={<ClientSettingsView />}></Route>
          <Route path="/klanten/:client" element={<Navigate to="./beslisboom" />} />
        </Routes>

        <Routes>          
          <Route path="/klanten" element={<ClientView />}></Route>
          <Route path="/beslisboom"></Route>
          <Route path="/entiteiten"></Route>
          <Route path="/gegevens"></Route>
          <Route path="/" element={<Navigate to="/klanten" />} />
        </Routes>
      </>}
    </div>
  )
}
