import React, { ReactElement, useEffect, useState } from 'react';
import '../styles/sidepanel.min.css';

import LogoSmall from '../res/logo-small.png';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { authorizedState } from '../atoms/global';
import axios from '../api/axios';

interface Props {
  children: React.ReactNode
}

export default function SidePanel({children}: Props): ReactElement {
  const setAuthorized = useSetRecoilState(authorizedState);
  const {client} = useParams()
  const [clientName, setClientName] = useState<string>("")

  useEffect(() => {
    if (client) {
      axios
        .get(`/load-client?c=${client}`)
        .then(res => {
          setClientName(res.data.name);
        })
    }
  }, [client])

  const navigate = useNavigate();

  function logout(): void {
    localStorage.removeItem("token");
    setAuthorized(false);
    navigate('/login');
  }

  return (
    <div className='sidepanel-container'>
      <div className="sidepanel">
        <div className="sidepanel-top">
          <h2 className="sidepanel-greet">{client ? clientName : "VVA Admin"}</h2>
        </div>
        <div className="sidepanel-bottom">
          <nav>
            <ul className="nav-top">{children}</ul>
            <ul className="nav-bottom">
              {client && <Link to="/klanten"><li className={"nav-inactive"}>Klanten</li></Link>}
              {client && <Link to="./instellingen"><li className={"nav-inactive"}>Instellingen</li></Link>}
              <li onClick={() => logout()} className="nav-inactive">Uitloggen</li>
      
              <a href="/"><img src={LogoSmall} alt="VVA Innovatisering" className="logo-small" /></a>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}
