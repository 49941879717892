import React from 'react'
import ListItem from './ListItem'
import '../styles/default.min.css'
import '../styles/data.min.css'

interface Props {
  children: Array<React.ReactNode>
}

export default function DataList({children}: Props) {
  return (
    <div className='data-list'>
      {children}
    </div>
  )
}